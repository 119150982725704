/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@font-face {   
    font-family: 'PTSansRegular';    //This is what we are going to call
    src: url('https://fonts.googleapis.com/css2?family=PT+Sans&display=swap');
}

@font-face {   
    font-family: 'PTSansBold';    //This is what we are going to call
    src: url('https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap'); //bold
}

@font-face {   
    font-family: 'PTSansItalic';    //This is what we are going to call
    src: url('assets/fonts/IvarTextHydro-Italic.ttf'); //italic
}

ion-modal.fullscreen {
    --width: 100%;
    --height: 100%;
    --background: transparent;
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.4);

    //--border-radius: 0;
}

ion-content {
    --background: #489c9c;
}

ion-button {
    text-transform: none;
}

.content {
    display: flex;
    justify-content: center;
    width: 100%;
}

.textBox {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    text-align: center;
    font-size: max(3.63vh, 24px);
    font-family: PTSansBold !important;
    background: #c5b9ac;
    color: white;
    box-shadow: 10px 10px 0 -2px #835889, 10px 10px 0 0 #c5b9ac;
}

.button {
    max-height: 60px;
    height: max(7vh, 60px);
    max-width: 280px;
    width: max(60vw, 190px);
}

.box {
    border: 2px solid #c5b9ac;
    border-radius: 15px;
}

.welcome-modal > .modal-wrapper.sc-ion-modal-md {
    height: 400px;
}


ion-modal.questions-modal::part(content),
.questions-modal > .modal-wrapper.sc-ion-modal-md {
    width: 950px;
    height: 800px;
    max-width: 85vw;
    max-height: 95vh;
}


ion-modal.questions-modal::part(content),
.bonus-questions-modal > .modal-wrapper.sc-ion-modal-md {
    width: 850px;
    height: 650px;
    border: solid 6px gold;
}